import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PageCover } from '@app/content/models';
import { SafePipe } from '@shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-page-cover',
  templateUrl: './page-cover.component.html',
  styleUrls: ['./page-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslateModule, SafePipe],
})
export class PageCoverComponent {
  @Input()
  public cover!: PageCover;
}
