import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  src: string;

  constructor(private translateService: TranslateService) {
    this.src =
      this.translateService.currentLang === 'ru'
        ? '/assets/images/teqball_logo_ru.png'
        : '/assets/images/teqball_logo.png';
  }
}
